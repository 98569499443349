<template>
  <div>
    <!-- <ge-autocomplete api_key="ge-0eb3a59a53c6159a"></ge-autocomplete> -->
    <v-autocomplete
      ref="autocompleteMain"
      :value="value"
      item-text="displayName"
      item-value="displayName"
      @input="updateValue($event)"
      :items="items"
      :loading="isLoading"
      :search-input.sync="searchAddress"
      :label="label"
      :placeholder="$t('AutocompleteAddressGeocode.placeholder')"
      :prepend-icon="prependIcon"
      clearable
      :outlined="outlined"
      :filled="filled"
      :disabled="disabled"
      return-object
      :filter="filterNone"
      v-bind="searchParams"
      :title="$t('AutocompleteAddressGeocode.title')"
      dense
    >
      <!--    <template slot="selection" slot-scope="{ item }">
      {{ item.displayName }}
    </template>

    <template slot="item" slot-scope="{ item }">
      {{ item.displayName }}
    </template>-->
      <template slot="append-outer">
        <v-icon
          @click="changeForwardGeocoding()"
          :title="forwardGeocodingTitle"
          >{{ forwardGeocodingIcon }}</v-icon
        >
      </template>
    </v-autocomplete>
    <!--<v-dialog
      v-model="findNewPlaceDialog"
      transition="dialog-top-transition"
      max-width="600px"
      eager
    >
      <v-toolbar color="secondary" dark dense flat>
        <v-toolbar-title>Pridanie novej adresy</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="findNewPlaceDialog">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <input
                    ref="autocomplete"
                    type="text"
                    placeholder="Enter a location"
                    style="
                      width: 100%;
                      border: 0;
                      border-bottom: 1px solid #d2d2d2;
                      outline: 0;
                      font-size: 16px;
                      color: #212121;
                      padding: 7px 0;
                      background: transparent;
                      transition: border-color 0.2s;
                    "
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              dense
              text
              @click.stop="findNewPlaceDialog = false"
              >{{ $t("btnClose") }}</v-btn
            >
            <v-btn color="success" dense text @click.stop="addNewAddress()"
              >Pridať</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>-->
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AutocompleteAddressGeocode",
  props: {
    value: {
      type: Object,
      default: null,
    },

    label: {
      type: String,
      default: "Address",
    },
    prependIcon: {
      type: String,
      default: "",
    },

    filled: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    searchParams: {
      type: Object,
      default: null,
    },
    addAddress: {
      type: Object,
      default: null,
    },
    addDefaultAddresses: {
      type: Array,
      default: null,
    },
    mapBounds: {
      type: Object,
      default: null,
    },
    setFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      isLoading: false,
      model: null,

      searchAddress: null,
      doNotSearchAddedAddress: false,
      forwardGeocoding: "focus",
      forwardGeocodingIcon: "mdi-map-marker-circle",
      forwardGeocodingTitle: this.$t(
        "AutocompleteAddressGeocode.iconAppendOuterTitleFocus"
      ),

      searchData: {
        // format: "json",
        // q: null,
        // country: null,
        // city: null,
        // taxiservicesId: null,
        // language: null,
        // limit: 10,
        // viewbox: null,
        //  bounded: 1,
        api_key: null,
        text: null,
        layers: null,
        //LAYERS: coarse,address,venue,street,intersection,postalcode,locality,
        //neighbourhood,county,localadmin,region,macrocounty,country,macroregion,
        //dependency,borough,macrohood,marinearea,disputed,empire,continent,ocean
        //focus.point.lat , focus.point.lon
        //focus: { point: { lat: null, lon: null,  }, },
        "focus.point.lat": null,
        "focus.point.lon": null,
        "boundary.country": null,
        "boundary.rect.min_lat": null,
        "boundary.rect.min_lon": null,
        "boundary.rect.max_lat": null,
        "boundary.rect.max_lon": null,
      },

      debounce: null,

      //Pridanie adresy do DB od Google
      //    findNewPlaceDialog: false,
      //Flag na oznacenie, ze adresa bola priamo vyhladana cez google, aby sa duplicitne nevyhladavala v lokalnej DB
      /*    newPlaceFlag: false,
      autocomplete: null,
      newPlace: null,
      newAddress: {
        active: true,
        amenityName: null,
        amenityType: null,
        categoryId: null,
        categoryName: null,
        city: null,
        cityDistrict: null,
        country: "Slovensko",
        countryCode: "SK",
        county: null,
        displayName: null,
        formattedAddress: null,
        houseNumber: null,
        id: null,
        latitude: null,
        longitude: null,
        neighbourhood: null,
        ordering: 0,
        postcode: null,
        priority: 0,
        road: null,
        state: null,
        suburb: null,
      },
*/
      //Snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },
  created() {
    this.getkey();
  },
  /*created() {
    const existingScript = Array.from(
      document.head.getElementsByTagName("script")
    ).find(
      (script) =>
        script.src ===
        "https://cdn.jsdelivr.net/npm/@geocodeearth/autocomplete-element/dist/bundle.js"
    );

    if (!existingScript) {
      const script = document.createElement("script");
      script.type = "module";
      script.src =
        "https://cdn.jsdelivr.net/npm/@geocodeearth/autocomplete-element/dist/bundle.js";
      script.onload = () => {
        // Tu môžete inicializovať vyhľadávanie adresy alebo vykonať ďalšie operácie
      };
      document.head.appendChild(script);
    } else {
      // Skript už existuje v hlavičke, môžete vykonať príslušné operácie
    }
  },*/

  mounted() {
    //this.initMap();
    this.initSearch();
  },

  computed: {},

  watch: {
    searchAddress(value) {
      //Ak nie je ziadna hodnota, nevyhladavat.
      if (!value) {
        return;
      }
      //Ak je adresa z google, nevyhladavat
      //     if (this.newPlaceFlag == true) {
      //       this.newPlaceFlag = false; //po jednorazovom obmedzeni vyhladavania ak je priamo z google, tak opat povolit vyhladavanie
      //       return;
      //     }
      //Ak je hodnota kratšia ako 3 znaky, tak nevyhladavat
      if (value.length < 3) {
        return;
      }
      // this.clearEntries();
      //this.searchData.format = "json";
      //this.searchData.q = value;
      this.searchData.text = value;
      this.searchData["boundary.country"] = this.searchParams.countryCode;
      /*this.searchData.countryCode = this.searchParams.countryCode;
      this.searchData.city = this.searchParams.city;*/
      //Nacitat z localstorage ID taxisluzby, lebo kazda taxisluzba si tvori vlasny zoznam adries/Places
      /* this.searchData.taxiserviceId = localStorage.getItem(
        "selectedTaxiserviceId"
      );*/
      //this.searchData.taxiserviceId = this.searchParams.taxiserviceId;
      //this.searchData.language = this.searchParams.language;
      //this.searchAddressApi();
      //Ak prisla adresa z mapy do pola v objekte "addAddress", tak ju nevyhladavat
      //console.log("this.doNotSearchAddedAddress", this.doNotSearchAddedAddress);
      if (this.doNotSearchAddedAddress == true) {
        this.doNotSearchAddedAddress = false;
      } else {
        // console.log("searchAddressApi");
        this.isLoading = true;
        this.fetchEntriesDebounced();
      }
    },
    /* findNewPlaceDialog: function (val) {
      this.$refs.autocomplete.focus();
    },*/
    addAddress: function () {
      //console.log("addAddress", this.addAddress);
      //Ak prisla adresa z mapy do pola v objekte "addAddress", tak ju nevyhladavat
      //Ak je komponent sucastou komponentu "AutocompleteAddressRemoteGeocode.vue", tak sa vyhladava
      if (
        this.addAddress.latitude == null &&
        this.addAddress.longitude == null
      ) {
        this.doNotSearchAddedAddress = false;
      } else {
        this.doNotSearchAddedAddress = true;
      }
      //if (this.addAddress) {
      /*this.items = this.addAddress;*/
      var temporaryAddress = [];
      temporaryAddress.latitude = this.addAddress.latitude;
      temporaryAddress.longitude = this.addAddress.longitude;
      temporaryAddress.displayName = this.addAddress.displayName;
      //console.log("addAddress temporaryAddress", temporaryAddress);
      this.items.push(Object.assign({}, temporaryAddress));
      this.updateValue(Object.assign({}, temporaryAddress));
      //console.log("addAddress this.items.push", this.items);
      //}
    },
    addDefaultAddresses: function () {
      //console.log("addDefaultAddresses", this.addDefaultAddresses);
      //if (this.addDefaultAddresses) {
      /*this.items = this.addDefaultAddresses;*/
      var temporaryAddress = [];
      var defaultAddress = this.addDefaultAddresses;
      if (defaultAddress) {
        defaultAddress.forEach((element) => {
          temporaryAddress = [];
          temporaryAddress.latitude = element.latitude;
          temporaryAddress.longitude = element.longitude;
          temporaryAddress.displayName = element.address;
          //console.log("addDefaultAddresses temporaryAddress", temporaryAddress);
          //this.updateValue(Object.assign({}, temporaryAddress));
          this.items.push(Object.assign({}, temporaryAddress));
          //console.log("addDefaultAddresses this.items.push", this.items);
        });
      }
      //}
    },
    mapBounds: function () {
      //this.autocomplete.setBounds(this.mapBounds);
      //console.log("autocomplete - mapBounds", this.mapBounds);
      //covert this.mapBounds (google.maps.LatLngBounds) to OSM NOMINATIM viewbox string
      //https://nominatim.org/release-docs/develop/api/Search/
      //https://stackoverflow.com/questions/14492284/center-a-map-exactly-over-a-location-given-by-latitude-and-longitude
      var viewbox = "";
      var sw = this.mapBounds.getSouthWest();
      var ne = this.mapBounds.getNorthEast();
      viewbox = sw.lng() + "," + sw.lat() + "," + ne.lng() + "," + ne.lat();
      //console.log("autocomplete - viewbox", viewbox);
      //this.searchData.viewbox = viewbox;
      //round to 6 decimal places and convert to number
      this.searchData["boundary.rect.min_lat"] =
        Math.round(sw.lat() * 1e3) / 1e3;
      this.searchData["boundary.rect.min_lon"] =
        Math.round(sw.lng() * 1e3) / 1e3;
      this.searchData["boundary.rect.max_lat"] =
        Math.round(ne.lat() * 1e3) / 1e3;
      this.searchData["boundary.rect.max_lon"] =
        Math.round(ne.lng() * 1e3) / 1e3;
      //from boundary find center of screen
      this.searchData["focus.point.lat"] =
        Math.round(((sw.lat() + ne.lat()) / 2) * 1e3) / 1e3;
      this.searchData["focus.point.lon"] =
        Math.round(((sw.lng() + ne.lng()) / 2) * 1e3) / 1e3;
      //console.log("autocomplete - searchData", this.searchData);
    },
    setFocus: function () {
      //console.log("setFocus", this.setFocus);
      if (this.setFocus == true) {
        setTimeout(() => {
          this.$refs.autocompleteMain.focus();
          //this.$refs.autocompleteMain.isMenuActive = true;
          this.$refs.autocompleteMain.activateMenu();
        }, 200);
      }
    },
  },

  methods: {
    //set searchData.api_key z api "dispatching/service-key" s parametrom "type=geocode" a "systemType=dispatch"
    getkey() {
      axios
        .get( process.env.VUE_APP_API_BASE_URL + `/dispatching/service-key`, {
          params: {
            type: "GEOCODE",
            systemType: "DISPATCH",
          },
        })
        .then((response) => {
          //find apikey from list if now is from validFrom to validTo and active=true
          var now = new Date();
          var data = response.data;
          var apikey = data.find(
            (element) =>
              new Date(element.validFrom) <= now &&
              new Date(element.validTo) >= now &&
              element.active == true
          );
          console.log("apikey", apikey);
          if (apikey) {
            this.searchData.api_key = apikey.token;
          }
          console.log("searchData.api_key", this.searchData.api_key);
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("searchAddressApi error", error);
          this.isLoading = false;
        });
    },

    clearSearch() {
      //console.log("test cleareable");
      var temporaryAddress = [];
      temporaryAddress.latitude = null;
      temporaryAddress.longitude = null;
      temporaryAddress.displayName = null;
      this.updateValue(Object.assign({}, temporaryAddress));
      this.items.push(Object.assign({}, temporaryAddress));
    },

    updateValue(value) {
      console.log("updateValue: ", value);
      if (!value) {
        var temporaryAddress = [];
        temporaryAddress.latitude = null;
        temporaryAddress.longitude = null;
        temporaryAddress.displayName = "";
        value = Object.assign({}, temporaryAddress);
      }
      this.$emit("input", value);
    },

    filter(item, queryText, itemText) {
      return item.displayName
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          queryText
            .toLocaleLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        );
    },
    filterNone(item, queryText, itemText) {
      // Vráťte všetky výsledky z API bez akéhokoľvek filtrovania
      return true;
    },
    changeForwardGeocoding() {
      if (this.forwardGeocoding === "boundary") {
        this.forwardGeocoding = "focus";
        this.forwardGeocodingIcon = "mdi-map-marker-circle";
        this.forwardGeocodingTitle = this.$t(
          "AutocompleteAddressGeocode.iconAppendOuterTitleFocus"
        );
      } else if (this.forwardGeocoding === "focus") {
        this.forwardGeocoding = "boundary";
        this.forwardGeocodingIcon = "mdi-overscan";
        this.forwardGeocodingTitle = this.$t(
          "AutocompleteAddressGeocode.iconAppendOuterTitleBoundary"
        );
      } else {
        this.forwardGeocoding = "focus";
        this.forwardGeocodingIcon = "mdi-map-marker-circle";
        this.forwardGeocodingTitle = this.$t(
          "AutocompleteAddressGeocode.iconAppendOuterTitleFocus"
        );
      }
    },

    searchAddressApi() {
      this.isLoading = true;
      //console.log("search: ", this.searchData.query),
      var searchDataReq = Object.assign({}, this.searchData);
      //console.log("searchAddressApi - searchDataReq pre", searchDataReq);
      //console.log("searchAddressApi - this.searchData", this.searchData);
      //Vymazat tie typy filtrovania, ktore nemaju byt pouzite
      if (this.forwardGeocoding == "boundary") {
        searchDataReq["focus.point.lat"] = null;
        searchDataReq["focus.point.lon"] = null;
      } else if (this.forwardGeocoding == "focus") {
        searchDataReq["boundary.rect.min_lat"] = null;
        searchDataReq["boundary.rect.min_lon"] = null;
        searchDataReq["boundary.rect.max_lat"] = null;
        searchDataReq["boundary.rect.max_lon"] = null;
      }
      //console.log("searchAddressApi - forwardGeocoding", this.forwardGeocoding);
      //console.log("searchAddressApi - searchDataReq", searchDataReq);
      axios
        /* .//get(process.env.VUE_APP_API_BASE_URL + `/dispatching/place/geo`, {*/
        //.get(`https://addresses.taxximo.com/places/geo`, {
        //.get(`https://places.taxximo.com/search.php`, {
        .get(`https://api.geocode.earth/v1/autocomplete`, {
          params: searchDataReq,
        })
        .then((res) => {
          //console.log("searchAddresssApi - response", res);
          if (res.data.features.length > 0) {
            var items = res.data.features;
            //zamenit display_name na displayName, lat (string) na latitude (number), lon na longitude
            items.forEach((element) => {
              element.displayName = element.properties.label;
              //delete element.display_name;
              element.latitude = parseFloat(element.geometry.coordinates[1]);
              //delete element.lat;
              element.longitude = parseFloat(element.geometry.coordinates[0]);
              //delete element.lon;
            });
            console.log("searchAddresssApi - items", items);
            this.items = items;
          } else {
            this.items = [];
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },

    clearEntries() {
      this.items = [];
    },

    fetchEntriesDebounced() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchAddressApi();
      }, 800); /* 500ms throttle */
    },
    /*  openFindNewPlaceDialog() {
      this.findNewPlaceDialog = true;
      //console.log("this.searchAddress: ", this.searchAddress);
      //console.log("this.value: ", this.value);
      this.$refs.autocomplete.value = this.searchAddress;
      setTimeout(() => {
        this.setFocusToAutocompleteField();
      }, 200);
    },*/
    setFocusToAutocompleteField() {
      this.$refs.autocomplete.focus();
    },
    /*    addNewAddress() {
      //Nastavenie Flagu, aby sa duplicitne nevyhladavala adresa ale priamo pouzila pre novu objednavku. Taky mensi workaround
      this.newPlaceFlag = true;
      //Adresa/string, ktora je vybrata vo vyhladavacom poli
      //this.searchAddress = String(this.$refs.autocomplete.value);
      //Object adresy, ktory bude odoslany na server
      var newAddress = this.newAddress;
      //Object adresy, ktory prisiel z Google
      var newPlace = this.newPlace;
      //console.log("newPlace", newPlace);
      //Temporary variables v strukture v ktorej prichadzaju z google API
      var latitude = "";
      var longitude = "";
      var displayName = "";
      var formattedAddress = "";
      var amenityName = "";
      var amenityType = "";
      var country = "";
      var country_code = "";
      var street_number = "";
      var route = "";
      //pozor, v US pisu inak oznacenie stvrte oproti EU, takze mozu prist 2 odlisne nazvy parametra
      var neighbourhood = "";
      var neighborhood = "";
      var sublocality = "";
      var sublocality_level_1 = "";
      var administrative_area_level_2 = "";
      var administrative_area_level_1 = "";
      var postal_code = "";
      var locality = "";
      var city = "";
      // ID = null vzdy pre nove adresy
      newAddress.id = null;
      //GPS skracujeme, aby neboli 1:1 s Google a je to aj odporucanie kvoli cachovaniu dat aby sa pracovalo s rozumnou toleranciu polohy
      //console.log("location.lat", newPlace.geometry.location.lat());
      //latitude = String(newPlace.geometry.location.lat()).substring(0, 9);
      latitude = newPlace.geometry.location.lat();
      //console.log("location.lng", newPlace.geometry.location.lng());
      //longitude = String(newPlace.geometry.location.lng()).substring(0, 9);
      longitude = newPlace.geometry.location.lng();
      //Mohli by sme pouzivat aj formatted_address, ktory prichadza z API, ale autocomplete verzia textu vyzera krajsie
      displayName = String(this.$refs.autocomplete.value);
      formattedAddress = newPlace.formatted_address;
      amenityName = newPlace.name ? String(newPlace.name) : null;
      //Typ objektu vieme zistit vlastne iba z typu ikony, preto si uchovame jej nazov
      amenityType = newPlace.icon
        ? String(newPlace.icon.split("/")[newPlace.icon.split("/").length - 1])
        : null;
      for (var u = 0; u < newPlace.address_components.length; u++) {
        var addr = newPlace.address_components[u];
        if (addr.types[0] == "country") {
          country = addr.long_name;
          country_code = addr.short_name;
        }
        if (addr.types[0] == "street_number") {
          street_number = addr.long_name;
        }
        if (addr.types[0] == "route") {
          route = addr.long_name;
        }
        if (addr.types[0] == "neighborhood") {
          neighbourhood = addr.long_name;
        }
        if (addr.types[0] == "neighborhood") {
          neighborhood = addr.long_name;
        }
        if (addr.types[0] == "sublocality") {
          sublocality = addr.long_name;
        }
        if (addr.types[0] == "sublocality_level_1") {
          sublocality_level_1 = addr.long_name;
        }
        if (addr.types[0] == "administrative_area_level_2") {
          administrative_area_level_2 = addr.long_name;
        }
        if (addr.types[0] == "administrative_area_level_1") {
          administrative_area_level_1 = addr.long_name;
        }
        if (addr.types[0] == "postal_code") {
          postal_code = addr.long_name;
        }
        if (addr.types[0] == "locality") {
          locality = addr.long_name;
        }
      }
      //Premapovanie objektov a vynimky
      if (
        (locality == undefined || locality == "") &&
        sublocality_level_1 != undefined &&
        sublocality_level_1 != ""
      ) {
        locality = sublocality_level_1;
        //	$('#place_city').val(administrative_area_level_2);
      } else if (locality == undefined || locality == "") {
        locality = administrative_area_level_2;
        //	$('#place_city').val(locality);
      }
      //	$('#place_country').val(country);
      //ošetrenie správneho uvedenia mesta napr. pre Bratislavu, lebo google občas vracia iba názvy štvrtí
      if (administrative_area_level_2 != undefined) {
        if (
          administrative_area_level_2.substring(0, 10) == "Bratislava" &&
          (locality == "Ružinov" ||
            locality == "Petržalka" ||
            locality == "Rača" ||
            locality == "Staré Mesto" ||
            locality == "Vrakuňa" ||
            locality == "Podunajské Biskupice" ||
            locality == "Nové Mesto" ||
            locality == "Vajnory" ||
            locality == "Karlova Ves" ||
            locality == "Dúbravka" ||
            locality == "Lamač" ||
            locality == "Devín" ||
            locality == "Devínska Nová Ves" ||
            locality == "Záhorská Bystrica" ||
            locality == "Jarovce" ||
            locality == "Rusovce" ||
            locality == "Čunovo")
        ) {
          city = "Bratislava";
        } else if (
          administrative_area_level_2.substring(0, 10) == "Bratislava" &&
          (sublocality == "Bratislava" ||
            sublocality == "Bratislava I" ||
            sublocality == "Bratislava II" ||
            sublocality == "Bratislava III" ||
            sublocality == "Bratislava IV" ||
            sublocality == "Bratislava V")
        ) {
          city = "Bratislava";
        } else {
          city = locality;
        }
      } else {
        city = locality;
      }

      // ID = null vzdy pre nove adresy
      newAddress.id = null;
      newAddress.latitude = latitude;
      newAddress.longitude = longitude;
      newAddress.displayName = displayName;
      newAddress.formattedAddress = formattedAddress;
      newAddress.country = country;
      newAddress.countryCode = country_code;
      newAddress.amenityName = amenityName;
      newAddress.amenityType = amenityType;
      newAddress.city = city;
      newAddress.cityDistrict = locality;
      newAddress.county = administrative_area_level_2;
      newAddress.houseNumber = street_number;
      newAddress.neighbourhood = neighbourhood ? neighbourhood : neighborhood;
      newAddress.postcode = postal_code;
      newAddress.road = route;
      newAddress.state = administrative_area_level_1;
      newAddress.suburb = sublocality;
      //Odovzdanie hodnoty do hlavného komponentu
      var temporaryAddress = [];
      temporaryAddress.latitude = latitude ? latitude : 0;
      temporaryAddress.longitude = longitude ? longitude : 0;
      temporaryAddress.displayName = displayName ? displayName : "";
      //this.$emit("input", Object.assign({}, temporaryAddress));
      this.updateValue(Object.assign({}, temporaryAddress));
      //Adresa/string, ktora je vybrata vo vyhladavacom poli
      //console.log("temporaryAddress", temporaryAddress);
      //this.value = Object.assign({}, temporaryAddress);
      this.items.push(Object.assign({}, temporaryAddress));
      //this.searchAddress = displayName;
      //console.log("displayName", displayName);
      //console.log("this.value", this.value);
      //Zaevidovanie adresy na server
      axios
        .put(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/place`,
          newAddress
        )
        .then((response) => {
          // console.log(response.data);
          var responseData = response.data;
          if (responseData.message == "ALREADY_EXISTS") {
            alert(
              "Duplicated record. Please search address regulary, do not add address via functionality to add address."
            );
            this.snackbar.show = "true";
            this.snackbar.message = "Duplicated record...";
            this.snackbar.color = "error";
          } else {
            this.snackbar.show = "true";
            this.snackbar.message = "Sucessfully updated...";
            this.snackbar.color = "success";
          }
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t('msgResponseError');
          this.snackbar.color = "error";
        });
      this.findNewPlaceDialog = false;
    }, */

    initSearch() {
      //const input = document.getElementById("pac-input");
      //  const input = /** @type {!HTMLInputElement} */ /* (this.$refs.autocomplete);*/

      /*   const options = {
        fields: [
          "formatted_address",
          "address_components",
          "geometry",
          "name",
          "icon",
        ],
        strictBounds: false,
        //types: ["establishment"],
        types: [],
      };*/
      /*  var bounds;
      //var boundsSw;
      //var boundsNe;
      if (!bounds || bounds == null) {
       boundsSw = new google.maps.LatLng(47.1, 17.1);
        boundsNe = new google.maps.LatLng(49.1, 19.1);
        //console.log("1", boundsNe);
        bounds = new google.maps.LatLngBounds(boundsSw, boundsNe);
        //console.log("bounds 2:", bounds);
        this.autocomplete = new google.maps.places.Autocomplete(input, options);
        var viewbox = sw.lng() + "," + sw.lat() + "," + ne.lng() + "," + ne.lat();
        //console.log("autocomplete - viewbox", viewbox);
        this.searchData.viewbox = viewbox;
      } else {
        bounds = this.mapBounds;*/
      //var viewbox = "16.9,47.2,19.1,49.1";
      //console.log("autocomplete - viewbox", viewbox);
      // this.searchData.viewbox = viewbox;

      //Alternativne stred mapy pre GEOCODE API
      // this.searchData["focus.point.lat"] = "47.2";
      // this.searchData["focus.point.lon"] = "16.9";
      // this.searchData["boundary.country"] = "SVK";
      this.searchData["boundary.rect.min_lat"] = "47.2";
      this.searchData["boundary.rect.min_lon"] = "16.9";
      this.searchData["boundary.rect.max_lat"] = "49.1";
      this.searchData["boundary.rect.max_lon"] = "19.1";

      /*}
      //console.log("bounds 3:", bounds);
      this.autocomplete.setBounds(bounds);
      //autocomplete.bindTo(bounds);
      this.autocomplete.addListener("place_changed", () => {
        const place = this.autocomplete.getPlace();
        this.newPlace = place;
        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }
        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          //console.log("place.geometry.viewport", place.geometry.viewport);
        } else {
          //console.log("place.geometry.location", place.geometry.location);
        }
        //console.log("place.geometry.location", place.geometry.location);
        //console.log("place.name", place.name);
        //console.log("place.formatted_address", place.formatted_address);
      });*/
    },
  },

  beforeDestroy() {
    clearInterval(this.debounce);
  },
};
</script>
