<template>
  <div>
    <v-autocomplete
      ref="autocompleteMain"
      :value="value"
      item-text="displayName"
      item-value="displayName"
      @input="updateValue($event)"
      :items="items"
      :loading="isLoading"
      :search-input.sync="searchAddress"
      :label="label"
      :placeholder="$t('AutocompleteAddressRemoteGeocode.placeholder')"
      clearable
      :outlined="outlined"
      :filled="filled"
      :disabled="disabled"
      return-object
      :filter="filter"
      v-bind="searchParams"
      :title="$t('AutocompleteAddressRemoteGeocode.title')"
      dense
      :color="prependIconColor"
      :hide-details="hideDetails"
    >
      <!--       :prepend-icon="prependIcon"
      @click:prepend="openFindNewPlaceDialog()"
       -->
      <!--    <template slot="selection" slot-scope="{ item }">
      {{ item.displayName }}
    </template>

    <template slot="item" slot-scope="{ item }">
      {{ item.displayName }}
    </template>-->
      <template v-slot:prepend>
        <v-icon
          :color="prependIconColor"
          @click="openFindNewPlaceDialog()"
          :title="$t('AutocompleteAddressRemoteGeocode.prependIconTitle')"
          :disabled="duplicatedAddressBan"
          >{{ prependIcon }}</v-icon
        >
      </template>
    </v-autocomplete>
    <!-- Dialog na Vytvorenie novej objednavky-->
    <v-dialog
      v-model="findNewPlaceDialog"
      transition="dialog-top-transition"
      max-width="600px"
      eager
    >
      <v-toolbar color="secondary" dark dense flat>
        <v-toolbar-title>{{
          $t("AutocompleteAddressRemoteGeocode.dialogTitle")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="findNewPlaceDialog">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <autocomplete-address-geocode
                    v-model="addSearchAddressModel"
                    ref="autocomplete"
                    :label="
                      $t(
                        'AutocompleteAddressRemoteGeocode.autocompleteAddLabel'
                      )
                    "
                    prepend-icon="fas fa-street-view"
                    :searchParams="addSearchParams"
                    :setFocus="addSearchAddressFocus"
                    :mapBounds="addMapBounds"
                    :addAddress="addSearchAddressToField"
                    prependIconColor="info"
                    :hideDetails="true"
                  >
                  </autocomplete-address-geocode>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              dense
              text
              @click.stop="findNewPlaceDialog = false"
              >{{ $t("AutocompleteAddressRemoteGeocode.btnClose") }}</v-btn
            >
            <v-btn color="success" dense text @click.stop="addNewAddress()">{{
              $t("AutocompleteAddressRemoteGeocode.btnAdd")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import AutocompleteAddressGeocode from "../components/AutocompleteAddressGeocode.vue";
export default {
  name: "AutocompleteAddressRemoteGeocode",
  components: {
    AutocompleteAddressGeocode,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },

    label: {
      type: String,
      default: "Address",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    prependIconColor: {
      type: String,
      default: "",
    },

    filled: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    hideDetails: {
      type: Boolean,
    },
    searchParams: {
      type: Object,
      default: null,
    },
    addAddress: {
      type: Object,
      default: null,
    },
    addDefaultAddresses: {
      type: Array,
      default: null,
    },
    mapBounds: {
      type: Object,
      default: null,
    },
    setFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      isLoading: false,
      model: null,
      //Pridanie adresy do DB od Geocode
      addSearchAddressModel: {
        displayName: null,
        latitude: null,
        longitude: null,
      },
      addSearchAddressFocus: false,
      addSearchAddressToField: {
        displayName: null,
        latitude: null,
        longitude: null,
      },
      addSearchParams: {
        query: null,
        country: null,
        city: null,
        taxiservicesId: null,
        language: null,
      },
      addMapBounds: {
        north: null,
        south: null,
        east: null,
        west: null,
      },

      searchAddress: null,
      doNotSearchAddedAddress: false,
      duplicatedAddressBan: false,

      searchData: {
        query: null,
        country: null,
        city: null,
        taxiservicesId: null,
        language: null,
      },

      debounce: null,

      //Pridanie adresy do DB od Google
      findNewPlaceDialog: false,
      //Flag na oznacenie, ze adresa bola priamo vyhladana cez google, aby sa duplicitne nevyhladavala v lokalnej DB
      newPlaceFlag: false,
      autocomplete: null,
      newPlace: null,
      newAddress: {
        active: true,
        amenityName: null,
        amenityType: null,
        categoryId: null,
        categoryName: null,
        city: null,
        cityDistrict: null,
        country: "Slovensko",
        countryCode: "SK",
        county: null,
        displayName: null,
        formattedAddress: null,
        houseNumber: null,
        id: null,
        latitude: null,
        longitude: null,
        neighbourhood: null,
        ordering: 0,
        postcode: null,
        priority: 0,
        road: null,
        state: null,
        suburb: null,
      },

      //Snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },

  created() {},

  mounted() {
    this.initSearch();
  },

  computed: {},

  watch: {
    searchAddress(value) {
      //Ak nie je ziadna hodnota, nevyhladavat.
      if (!value) {
        return;
      }
      //Ak je adresa z google, nevyhladavat
      if (this.newPlaceFlag == true) {
        this.newPlaceFlag = false; //po jednorazovom obmedzeni vyhladavania ak je priamo z google, tak opat povolit vyhladavanie
        return;
      }
      //Ak je hodnota kratšia ako 3 znaky, tak nevyhladavat
      if (value.length < 3) {
        return;
      }
      // this.clearEntries();
      this.searchData.query = value;
      this.searchData.countryCode = this.searchParams.countryCode;
      this.searchData.city = this.searchParams.city;
      //Nacitat z localstorage ID taxisluzby, lebo kazda taxisluzba si tvori vlasny zoznam adries/Places
      this.searchData.taxiserviceId = localStorage.getItem(
        "selectedTaxiserviceId"
      );
      //this.searchData.taxiserviceId = this.searchParams.taxiserviceId;
      //this.searchData.language = this.searchParams.language;
      //this.searchAddressApi();
      //Ak prisla adresa z mapy do pola v objekte "addAddress", tak ju nevyhladavat
      if (this.doNotSearchAddedAddress == true) {
        this.doNotSearchAddedAddress = false;
      } else {
        //console.log("searchAddressApi");
        this.isLoading = true;
        this.fetchEntriesDebounced();
      }
    },
    /* findNewPlaceDialog: function (val) {
      this.$refs.autocomplete.focus();
    },*/
    addAddress: function () {
      //console.log("addAddress", this.addAddress);
      //if (this.addAddress) {
      /*this.items = this.addAddress;*/
      this.doNotSearchAddedAddress = true;
      var temporaryAddress = [];
      temporaryAddress.latitude = this.addAddress.latitude;
      temporaryAddress.longitude = this.addAddress.longitude;
      temporaryAddress.displayName = this.addAddress.displayName;
      //console.log("addAddress temporaryAddress", temporaryAddress);
      this.updateValue(Object.assign({}, temporaryAddress));
      this.items.push(Object.assign({}, temporaryAddress));
      //console.log("addAddress this.items.push", this.items);
      //}
    },
    addDefaultAddresses: function () {
      //console.log("addDefaultAddresses", this.addDefaultAddresses);
      //if (this.addDefaultAddresses) {
      /*this.items = this.addDefaultAddresses;*/
      var temporaryAddress = [];
      var defaultAddress = this.addDefaultAddresses;
      if (defaultAddress.length > 0) {
        //clear items
        this.items = [];
      }
      if (defaultAddress) {
        defaultAddress.forEach((element) => {
          temporaryAddress = [];
          temporaryAddress.latitude = element.latitude;
          temporaryAddress.longitude = element.longitude;
          temporaryAddress.displayName = element.address;
          //console.log("addDefaultAddresses temporaryAddress", temporaryAddress);
          //this.updateValue(Object.assign({}, temporaryAddress));
          this.items.push(Object.assign({}, temporaryAddress));
          //console.log("addDefaultAddresses this.items.push", this.items);
        });
      }
      //}
    },
    mapBounds: function () {
      this.addMapBounds = this.mapBounds;
      //console.log("autocomplete - mapBounds", this.mapBounds);
    },
    setFocus: function () {
      //console.log("setFocus", this.setFocus);
      if (this.setFocus == true) {
        setTimeout(() => {
          this.$refs.autocompleteMain.focus();
          //this.$refs.autocompleteMain.isMenuActive = true;
          this.$refs.autocompleteMain.activateMenu();
        }, 200);
      }
    },
  },

  methods: {
    clearSearch() {
      //console.log("test cleareable");
      var temporaryAddress = [];
      temporaryAddress.latitude = null;
      temporaryAddress.longitude = null;
      temporaryAddress.displayName = null;
      this.updateValue(Object.assign({}, temporaryAddress));
      this.items.push(Object.assign({}, temporaryAddress));
    },

    updateValue(value) {
      //console.log("updateValue: ", value);
      if (!value) {
        var temporaryAddress = [];
        temporaryAddress.latitude = null;
        temporaryAddress.longitude = null;
        temporaryAddress.displayName = "";
        value = Object.assign({}, temporaryAddress);
      }
      this.$emit("input", value);
    },

    filter(item, queryText, itemText) {
      return item.displayName
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          queryText
            .toLocaleLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        );
    },
    filterNone(item, queryText, itemText) {
      // Vráťte všetky výsledky z API bez akéhokoľvek filtrovania
      return true;
    },

    searchAddressApi() {
      this.isLoading = true;
      //console.log("search: ", this.searchData.query),
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/place/geo`, {
          //.get(`https://addresses.taxximo.com/places/geo`, {
          params: this.searchData,
        })
        .then((res) => {
          //console.log("searchAddresssApi - response", res);
          if (res.data.length > 0) {
            this.items = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },

    clearEntries() {
      this.items = [];
    },

    fetchEntriesDebounced() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchAddressApi();
      }, 800); /* 500ms throttle */
    },
    openFindNewPlaceDialog() {
      this.newPlace = null;
      if (this.searchAddress == null || this.searchAddress == "") {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "AutocompleteAddressRemoteGeocode.msgAddAddressNotSearched"
        );
        this.snackbar.color = "error";
        return;
      } else if (this.searchAddress.length < 2) {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "AutocompleteAddressRemoteGeocode.msgAddAddressNotSearched"
        );
        this.snackbar.color = "error";
        return;
      }
      this.findNewPlaceDialog = true;
      //console.log("this.searchAddress: ", this.searchAddress);
      //console.log("this.value: ", this.value);
      if (this.searchAddress != null && this.searchAddress != "") {
        this.addSearchAddressToField.displayName = this.searchAddress;
        this.addSearchAddressToField.latitude = null;
        this.addSearchAddressToField.longitude = null;
        this.addSearchAddressToField = Object.assign(
          {},
          this.addSearchAddressToField
        );
        setTimeout(() => {
          this.setFocusToAutocompleteField();
        }, 200);
      }
    },
    setFocusToAutocompleteField() {
      //this.$refs.autocomplete.focus();
      this.addSearchAddressFocus = true;
    },
    addNewAddress() {
      //Nastavenie Flagu, aby sa duplicitne nevyhladavala adresa ale priamo pouzila pre novu objednavku. Taky mensi workaround
      this.newPlaceFlag = true;
      //Adresa/string, ktora je vybrata vo vyhladavacom poli
      //this.searchAddress = String(this.$refs.autocomplete.value);
      //Object adresy, ktory bude odoslany na server
      var newAddress = this.newAddress;
      //Object adresy, ktory prisiel z Google
      var newPlace = this.addSearchAddressModel;
      //ak je newPlace prazdny, tak sa nevytvori nova adresa a ukáže alert okno
      if (newPlace == null) {
        alert(this.$t("AutocompleteAddressRemote.msgAddErrorEmpty"));
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "AutocompleteAddressRemote.msgAddErrorEmpty"
        );
        this.snackbar.color = "error";
        return;
      }
      //console.log("newPlace", newPlace);
      //Temporary variables v strukture v ktorej prichadzaju z google API
      var latitude = "";
      var longitude = "";
      var displayName = "";
      var formattedAddress = "";
      var amenityName = "";
      var amenityType = "";
      var country = "";
      var country_code = "";
      var street_number = "";
      var route = "";
      //pozor, v US pisu inak oznacenie stvrte oproti EU, takze mozu prist 2 odlisne nazvy parametra
      var neighbourhood = "";
      var neighborhood = "";
      var sublocality = "";
      var sublocality_level_1 = "";
      var administrative_area_level_2 = "";
      var administrative_area_level_1 = "";
      var postal_code = "";
      var locality = "";
      var city = "";
      // ID = null vzdy pre nove adresy
      newAddress.id = null;
      //GPS skracujeme, aby neboli 1:1 s Google a je to aj odporucanie kvoli cachovaniu dat aby sa pracovalo s rozumnou toleranciu polohy
      //console.log("location.lat", newPlace.geometry.location.lat());
      //latitude = String(newPlace.geometry.location.lat()).substring(0, 9);
      latitude = newPlace.geometry.coordinates[1];
      //console.log("location.lng", newPlace.geometry.location.lng());
      //longitude = String(newPlace.geometry.location.lng()).substring(0, 9);
      longitude = newPlace.geometry.coordinates[0];
      //Mohli by sme pouzivat aj formatted_address, ktory prichadza z API, ale autocomplete verzia textu vyzera krajsie
      //displayName = String(this.$refs.autocomplete.value);
      displayName = newPlace.displayName;
      formattedAddress = newPlace.properties.label;
      amenityName = newPlace.properties.name
        ? String(newPlace.properties.name)
        : null;
      //Typ objektu vieme zistit vlastne iba z typu ikony, preto si uchovame jej nazov
      amenityType = null; //newPlace.icon ? String(newPlace.icon.split("/")[newPlace.icon.split("/").length - 1]) : null;
      var country = newPlace.properties.country;
      var country_code = newPlace.properties.country_code;
      var street_number = newPlace.properties.housenumber;
      var route = newPlace.properties.street;
      //pozor, v US pisu inak oznacenie stvrte oproti EU, takze mozu prist 2 odlisne nazvy parametra
      var neighbourhood = null; // Neprichadza z Geocode Earth API, nastavujeme na null
      var neighborhood = null; // Neprichadza z Geocode Earth API, nastavujeme na null
      var sublocality = null; // Neprichadza z Geocode Earth API, nastavujeme na null
      var sublocality_level_1 = ""; // Neprichadza z Geocode Earth API, nastavujeme na null
      var administrative_area_level_2 = newPlace.properties.county;
      var administrative_area_level_1 = newPlace.properties.region;
      var postal_code = newPlace.properties.postalcode;
      var locality = newPlace.properties.locality;
      var city = newPlace.properties.locality;

      // ID = null vzdy pre nove adresy
      newAddress.id = null;
      newAddress.latitude = latitude;
      newAddress.longitude = longitude;
      newAddress.displayName = displayName;
      newAddress.formattedAddress = formattedAddress;
      newAddress.country = country;
      newAddress.countryCode = country_code;
      newAddress.amenityName = amenityName;
      newAddress.amenityType = amenityType;
      newAddress.city = city;
      newAddress.cityDistrict = locality;
      newAddress.county = administrative_area_level_2;
      newAddress.houseNumber = street_number;
      newAddress.neighbourhood = neighbourhood ? neighbourhood : neighborhood;
      newAddress.postcode = postal_code;
      newAddress.road = route;
      newAddress.state = administrative_area_level_1;
      newAddress.suburb = sublocality;
      //Odovzdanie hodnoty do hlavného komponentu
      var temporaryAddress = [];
      temporaryAddress.latitude = latitude ? latitude : 0;
      temporaryAddress.longitude = longitude ? longitude : 0;
      temporaryAddress.displayName = displayName ? displayName : "";
      //this.$emit("input", Object.assign({}, temporaryAddress));
      this.updateValue(Object.assign({}, temporaryAddress));
      //Adresa/string, ktora je vybrata vo vyhladavacom poli
      //console.log("temporaryAddress", temporaryAddress);
      //this.value = Object.assign({}, temporaryAddress);
      this.items.push(Object.assign({}, temporaryAddress));
      //this.searchAddress = displayName;
      //console.log("displayName", displayName);
      //console.log("this.value", this.value);
      //Zaevidovanie adresy na server
      axios
        .put(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/place`,
          newAddress
        )
        .then((response) => {
          // console.log(response.data);
          var responseData = response.data;
          if (responseData.message == "ALREADY_EXISTS") {
            alert(
              this.$t(
                "AutocompleteAddressRemoteGeocode.msgAddErrorDuplicatedAlert"
              )
            );
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "AutocompleteAddressRemoteGeocode.msgAddErrorDuplicated"
            );
            this.snackbar.color = "error";
            //Evidovanie poctu duplicit do cookie a nastavenie Ban-u po 6 pokusoch
            var addingExistingAddressCounter = this.getCookie(
              "addingExistingAddressCounter"
            )
              ? parseInt(
                  JSON.parse(this.getCookie("addingExistingAddressCounter"))
                )
              : 0;
            if (addingExistingAddressCounter > 5) {
              this.duplicatedAddressBan = true;
              alert(
                this.$t(
                  "AutocompleteAddressRemoteGeocode.msgAddErrorDuplicatedBan"
                )
              );
              this.snackbar.show = "true";
              this.snackbar.message = this.$t(
                "AutocompleteAddressRemoteGeocode.msgAddErrorDuplicatedBan"
              );
              this.snackbar.color = "error";
            }
            this.setCookie(
              "addingExistingAddressCounter",
              JSON.stringify(addingExistingAddressCounter + 1),
              0.04
            ); //zablokovať na 1 hodinu
          } else {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "AutocompleteAddressRemoteGeocode.msgAddSuccess"
            );
            this.snackbar.color = "success";
          }
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgUpdateError");
          this.snackbar.color = "error";
        });
      this.findNewPlaceDialog = false;
    },

    initSearch() {
      var bounds;
      var boundsSw;
      var boundsNe;
      if (!bounds || bounds == null) {
        boundsSw = new google.maps.LatLng(47.1, 17.1);
        boundsNe = new google.maps.LatLng(49.1, 19.1);
        //console.log("1", boundsNe);
        bounds = new google.maps.LatLngBounds(boundsSw, boundsNe);
        //console.log("bounds 2:", bounds);
      } else {
        bounds = this.mapBounds;
      }
    },
    //Vytvorenie a aktualizácia cookie
    setCookie(nameC, valueC, daysToLive) {
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = nameC + "=" + encodeURIComponent(valueC);
      if (typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires after the specified number of days */
        cookie +=
          "; max-age=" + daysToLive * 24 * 60 * 60 + "; path=/;SameSite=strict"; //; domain=taxximo.com
        document.cookie = cookie;
      }
    },
    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },
  },

  beforeDestroy() {
    clearInterval(this.debounce);
  },
};
</script>
