<template>
  <div>
    <v-autocomplete
      ref="autocompleteMain"
      :value="value"
      item-text="displayName"
      item-value="displayName"
      @input="updateValue($event)"
      :items="items"
      :loading="isLoading"
      :search-input.sync="searchAddress"
      :label="label"
      :placeholder="$t('AutocompleteAddressRemote.placeholder')"
      clearable
      :outlined="outlined"
      :filled="filled"
      :disabled="disabled"
      return-object
      :filter="filter"
      v-bind="searchParams"
      :title="$t('AutocompleteAddressRemote.title')"
      dense
      :color="prependIconColor"
      :hide-details="hideDetails"
    >
      <!--       :prepend-icon="prependIcon"
      @click:prepend="openFindNewPlaceDialog()"
       -->
      <!--    <template slot="selection" slot-scope="{ item }">
      {{ item.displayName }}
    </template>

    <template slot="item" slot-scope="{ item }">
      {{ item.displayName }}
    </template>-->
      <template v-slot:prepend>
        <v-icon
          :color="prependIconColor"
          @click="openFindNewPlaceDialog()"
          :title="$t('AutocompleteAddressRemote.prependIconTitle')"
          :disabled="duplicatedAddressBan"
          >{{ prependIcon }}</v-icon
        >
      </template>
    </v-autocomplete>
    <!-- Dialog na Vyhladanie a zaevidovanie novej adresy-->
    <v-dialog
      v-model="findNewPlaceDialog"
      transition="dialog-top-transition"
      max-width="600px"
      eager
    >
      <v-toolbar color="secondary" dark dense flat>
        <v-toolbar-title>{{
          $t("AutocompleteAddressRemote.dialogTitle")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="findNewPlaceDialog">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <input
                    ref="autocomplete"
                    type="text"
                    :placeholder="
                      $t('AutocompleteAddressRemote.dialogInputPlaceholder')
                    "
                    style="
                      width: 100%;
                      border: 0;
                      border-bottom: 1px solid #d2d2d2;
                      outline: 0;
                      font-size: 16px;
                      color: #212121;
                      padding: 7px 0;
                      background: #ffffff;
                      transition: border-color 0.2s;
                    "
                  />
                </v-col>
              </v-row>
              <!--<div class="pac-card" id="pac-card">
                <div>
                  <div id="title">Autocomplete search</div>
                  <div id="type-selector" class="pac-controls">
                    <input
                      type="radio"
                      name="type"
                      id="changetype-all"
                      checked="checked"
                    />
                    <label for="changetype-all">All</label>

                    <input
                      type="radio"
                      name="type"
                      id="changetype-establishment"
                    />
                    <label for="changetype-establishment">establishment</label>

                    <input type="radio" name="type" id="changetype-address" />
                    <label for="changetype-address">address</label>

                    <input type="radio" name="type" id="changetype-geocode" />
                    <label for="changetype-geocode">geocode</label>

                    <input type="radio" name="type" id="changetype-cities" />
                    <label for="changetype-cities">(cities)</label>

                    <input type="radio" name="type" id="changetype-regions" />
                    <label for="changetype-regions">(regions)</label>
                  </div>
                  <br />
                  <div id="strict-bounds-selector" class="pac-controls">
                    <input
                      type="checkbox"
                      id="use-location-bias"
                      value=""
                      checked
                    />
                    <label for="use-location-bias">Bias to map viewport</label>

                    <input type="checkbox" id="use-strict-bounds" value="" />
                    <label for="use-strict-bounds">Strict bounds</label>
                  </div>
                </div>
                <div id="pac-container">
                  <input
                    ref="autocomplete"
                    id="pac-input"
                    type="text"
                    placeholder="Enter a location"
                  />
                </div>
              </div>
              <div id="map"></div>
              <div id="infowindow-content">
                <span id="place-name" class="title"></span><br />
                <span id="place-address"></span></div
            >-->
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              dense
              text
              @click.stop="findNewPlaceDialog = false"
              >{{ $t("AutocompleteAddressRemote.btnClose") }}</v-btn
            >
            <v-btn color="success" dense text @click.stop="addNewAddress()">{{
              $t("AutocompleteAddressRemote.btnAdd")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AutocompleteAddressRemote",
  props: {
    value: {
      type: Object,
      default: null,
    },

    label: {
      type: String,
      default: "Address",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    prependIconColor: {
      type: String,
      default: "",
    },

    filled: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    hideDetails: {
      type: Boolean,
    },
    searchParams: {
      type: Object,
      default: null,
    },
    addAddress: {
      type: Object,
      default: null,
    },
    addDefaultAddresses: {
      type: Array,
      default: null,
    },
    mapBounds: {
      type: Object,
      default: null,
    },
    setFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      isLoading: false,
      model: null,

      searchAddress: null,
      doNotSearchAddedAddress: false,
      duplicatedAddressBan: false,

      searchData: {
        query: null,
        country: null,
        city: null,
        taxiservicesId: null,
        language: null,
      },

      debounce: null,

      //Pridanie adresy do DB od Google
      findNewPlaceDialog: false,
      //Flag na oznacenie, ze adresa bola priamo vyhladana cez google, aby sa duplicitne nevyhladavala v lokalnej DB
      newPlaceFlag: false,
      autocomplete: null,
      newPlace: null,
      newAddress: {
        active: true,
        amenityName: null,
        amenityType: null,
        categoryId: null,
        categoryName: null,
        city: null,
        cityDistrict: null,
        country: "Slovensko",
        countryCode: "SK",
        county: null,
        displayName: null,
        formattedAddress: null,
        houseNumber: null,
        id: null,
        latitude: null,
        longitude: null,
        neighbourhood: null,
        ordering: 0,
        postcode: null,
        priority: 0,
        road: null,
        state: null,
        suburb: null,
      },

      //Snackbar
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },

  created() {
    /*window.initSearch = this.initSearch;
    let googleMapsScript = document.createElement("script");
    googleMapsScript.setAttribute(
      "src",
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyAdG-Vz3YTYE4F5MTALr6-rU1XbQhD-9Ig&libraries=places&callback=initSearch"
    );
    googleMapsScript.setAttribute("async", "");
    googleMapsScript.setAttribute("defer", "");
    document.head.appendChild(googleMapsScript);*/
  },

  mounted() {
    //this.initMap();
    this.initSearch();
  },

  computed: {},

  watch: {
    searchAddress(value) {
      //Ak nie je ziadna hodnota, nevyhladavat.
      if (!value) {
        return;
      }
      //Ak je adresa z google, nevyhladavat
      if (this.newPlaceFlag == true) {
        this.newPlaceFlag = false; //po jednorazovom obmedzeni vyhladavania ak je priamo z google, tak opat povolit vyhladavanie
        return;
      }
      //Ak je hodnota kratšia ako 3 znaky, tak nevyhladavat
      if (value.length < 3) {
        return;
      }
      // this.clearEntries();
      this.searchData.query = value;
      this.searchData.countryCode = this.searchParams.countryCode;
      this.searchData.city = this.searchParams.city;
      //Nacitat z localstorage ID taxisluzby, lebo kazda taxisluzba si tvori vlasny zoznam adries/Places
      this.searchData.taxiserviceId = localStorage.getItem(
        "selectedTaxiserviceId"
      );
      //this.searchData.taxiserviceId = this.searchParams.taxiserviceId;
      //this.searchData.language = this.searchParams.language;
      //this.searchAddressApi();
      //Ak prisla adresa z mapy do pola v objekte "addAddress", tak ju nevyhladavat
      if (this.doNotSearchAddedAddress == true) {
        this.doNotSearchAddedAddress = false;
      } else {
        //console.log("searchAddressApi");
        this.isLoading = true;
        this.fetchEntriesDebounced();
      }
    },
    /* findNewPlaceDialog: function (val) {
      this.$refs.autocomplete.focus();
    },*/
    addAddress: function () {
      console.log("addAddress", this.addAddress);
      //if (this.addAddress) {
      /*this.items = this.addAddress;*/
      this.doNotSearchAddedAddress = true;
      var temporaryAddress = [];
      temporaryAddress.latitude = this.addAddress.latitude;
      temporaryAddress.longitude = this.addAddress.longitude;
      temporaryAddress.displayName = this.addAddress.displayName;
      //console.log("addAddress temporaryAddress", temporaryAddress);
      this.updateValue(Object.assign({}, temporaryAddress));
      this.items.push(Object.assign({}, temporaryAddress));
      //console.log("addAddress this.items.push", this.items);
      //}
    },
    addDefaultAddresses: function () {
      //console.log("addDefaultAddresses", this.addDefaultAddresses);
      if (this.addDefaultAddresses) {
        /*this.items = this.addDefaultAddresses;*/
        var temporaryAddress = [];
        var defaultAddress = this.addDefaultAddresses;
        if (defaultAddress.length > 0) {
          //clear items
          this.items = [];
        }
        if (defaultAddress) {
          defaultAddress.forEach((element) => {
            temporaryAddress = [];
            temporaryAddress.latitude = element.latitude;
            temporaryAddress.longitude = element.longitude;
            temporaryAddress.displayName = element.address;
            //console.log("addDefaultAddresses temporaryAddress", temporaryAddress);
            //this.updateValue(Object.assign({}, temporaryAddress));
            this.items.push(Object.assign({}, temporaryAddress));
            //console.log("addDefaultAddresses this.items.push", this.items);
          });
        }
      }
    },
    mapBounds: function () {
      this.autocomplete.setBounds(this.mapBounds);
      //console.log("autocomplete - mapBounds", this.mapBounds);
    },
    setFocus: function () {
      //console.log("setFocus", this.setFocus);
      if (this.setFocus == true) {
        setTimeout(() => {
          this.$refs.autocompleteMain.focus();
          //this.$refs.autocompleteMain.isMenuActive = true;
          this.$refs.autocompleteMain.activateMenu();
        }, 200);
      }
    },
  },

  methods: {
    clearSearch() {
      //console.log("test cleareable");
      var temporaryAddress = [];
      temporaryAddress.latitude = null;
      temporaryAddress.longitude = null;
      temporaryAddress.displayName = null;
      this.updateValue(Object.assign({}, temporaryAddress));
      this.items.push(Object.assign({}, temporaryAddress));
    },

    updateValue(value) {
      //console.log("updateValue: ", value);
      if (!value) {
        var temporaryAddress = [];
        temporaryAddress.latitude = null;
        temporaryAddress.longitude = null;
        temporaryAddress.displayName = "";
        value = Object.assign({}, temporaryAddress);
      }
      this.$emit("input", value);
    },

    filter(item, queryText, itemText) {
      return item.displayName
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          queryText
            .toLocaleLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        );
    },
    filterNone(item, queryText, itemText) {
      // Vráťte všetky výsledky z API bez akéhokoľvek filtrovania
      return true;
    },

    searchAddressApi() {
      this.isLoading = true;
      //console.log("search: ", this.searchData.query),
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/place/geo`, {
          //.get(`https://addresses.taxximo.com/places/geo`, {
          params: this.searchData,
        })
        .then((res) => {
          //console.log("searchAddresssApi - response", res);
          if (res.data.length > 0) {
            this.items = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },

    clearEntries() {
      this.items = [];
    },

    fetchEntriesDebounced() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchAddressApi();
      }, 800); /* 500ms throttle */
    },
    openFindNewPlaceDialog() {
      this.newPlace = null;
      if (this.searchAddress == null || this.searchAddress == "") {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "AutocompleteAddressRemote.msgAddAddressNotSearched"
        );
        this.snackbar.color = "error";
        return;
      } else if (this.searchAddress.length < 2) {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t(
          "AutocompleteAddressRemote.msgAddAddressNotSearched"
        );
        this.snackbar.color = "error";
        return;
      }
      this.findNewPlaceDialog = true;
      //console.log("this.searchAddress: ", this.searchAddress);
      //console.log("this.value: ", this.value);
      this.$refs.autocomplete.value = this.searchAddress;
      setTimeout(() => {
        this.setFocusToAutocompleteField();
      }, 200);
    },
    setFocusToAutocompleteField() {
      this.$refs.autocomplete.focus();
    },
    addNewAddress() {
      //Nastavenie Flagu, aby sa duplicitne nevyhladavala adresa ale priamo pouzila pre novu objednavku. Taky mensi workaround
      this.newPlaceFlag = true;
      //Adresa/string, ktora je vybrata vo vyhladavacom poli
      //this.searchAddress = String(this.$refs.autocomplete.value);
      //Object adresy, ktory bude odoslany na server
      var newAddress = this.newAddress;
      //Object adresy, ktory prisiel z Google
      var newPlace = this.newPlace;
      //ak je newPlace prazdny, tak sa nevytvori nova adresa a ukáže alert okno
      if (newPlace == null) {
        alert(this.$t("AutocompleteAddressRemote.msgAddErrorEmpty"));
        this.snackbar.show = "true";
        this.snackbar.message = this.$t("AutocompleteAddressRemote.msgAddErrorEmpty");
        this.snackbar.color = "error";
        return;
      }
      //console.log("newPlace", newPlace);
      //Temporary variables v strukture v ktorej prichadzaju z google API
      var latitude = "";
      var longitude = "";
      var displayName = "";
      var formattedAddress = "";
      var amenityName = "";
      var amenityType = "";
      var country = "";
      var country_code = "";
      var street_number = "";
      var route = "";
      //pozor, v US pisu inak oznacenie stvrte oproti EU, takze mozu prist 2 odlisne nazvy parametra
      var neighbourhood = "";
      var neighborhood = "";
      var sublocality = "";
      var sublocality_level_1 = "";
      var administrative_area_level_2 = "";
      var administrative_area_level_1 = "";
      var postal_code = "";
      var locality = "";
      var city = "";
      // ID = null vzdy pre nove adresy
      newAddress.id = null;
      //GPS skracujeme, aby neboli 1:1 s Google a je to aj odporucanie kvoli cachovaniu dat aby sa pracovalo s rozumnou toleranciu polohy
      //console.log("location.lat", newPlace.geometry.location.lat());
      //latitude = String(newPlace.geometry.location.lat()).substring(0, 9);
      latitude = newPlace.geometry.location.lat();
      //console.log("location.lng", newPlace.geometry.location.lng());
      //longitude = String(newPlace.geometry.location.lng()).substring(0, 9);
      longitude = newPlace.geometry.location.lng();
      //Mohli by sme pouzivat aj formatted_address, ktory prichadza z API, ale autocomplete verzia textu vyzera krajsie
      displayName = String(this.$refs.autocomplete.value);
      formattedAddress = newPlace.formatted_address;
      amenityName = newPlace.name ? String(newPlace.name) : null;
      //Typ objektu vieme zistit vlastne iba z typu ikony, preto si uchovame jej nazov
      amenityType = newPlace.icon
        ? String(newPlace.icon.split("/")[newPlace.icon.split("/").length - 1])
        : null;
      for (var u = 0; u < newPlace.address_components.length; u++) {
        var addr = newPlace.address_components[u];
        if (addr.types[0] == "country") {
          country = addr.long_name;
          country_code = addr.short_name;
        }
        if (addr.types[0] == "street_number") {
          street_number = addr.long_name;
        }
        if (addr.types[0] == "route") {
          route = addr.long_name;
        }
        if (addr.types[0] == "neighborhood") {
          neighbourhood = addr.long_name;
        }
        if (addr.types[0] == "neighborhood") {
          neighborhood = addr.long_name;
        }
        if (addr.types[0] == "sublocality") {
          sublocality = addr.long_name;
        }
        if (addr.types[0] == "sublocality_level_1") {
          sublocality_level_1 = addr.long_name;
        }
        if (addr.types[0] == "administrative_area_level_2") {
          administrative_area_level_2 = addr.long_name;
        }
        if (addr.types[0] == "administrative_area_level_1") {
          administrative_area_level_1 = addr.long_name;
        }
        if (addr.types[0] == "postal_code") {
          postal_code = addr.long_name;
        }
        if (addr.types[0] == "locality") {
          locality = addr.long_name;
        }
      }
      //Premapovanie objektov a vynimky
      if (
        (locality == undefined || locality == "") &&
        sublocality_level_1 != undefined &&
        sublocality_level_1 != ""
      ) {
        locality = sublocality_level_1;
        //	$('#place_city').val(administrative_area_level_2);
      } else if (locality == undefined || locality == "") {
        locality = administrative_area_level_2;
        //	$('#place_city').val(locality);
      }
      //	$('#place_country').val(country);
      //ošetrenie správneho uvedenia mesta napr. pre Bratislavu, lebo google občas vracia iba názvy štvrtí
      if (administrative_area_level_2 != undefined) {
        if (
          administrative_area_level_2.substring(0, 10) == "Bratislava" &&
          (locality == "Ružinov" ||
            locality == "Petržalka" ||
            locality == "Rača" ||
            locality == "Staré Mesto" ||
            locality == "Vrakuňa" ||
            locality == "Podunajské Biskupice" ||
            locality == "Nové Mesto" ||
            locality == "Vajnory" ||
            locality == "Karlova Ves" ||
            locality == "Dúbravka" ||
            locality == "Lamač" ||
            locality == "Devín" ||
            locality == "Devínska Nová Ves" ||
            locality == "Záhorská Bystrica" ||
            locality == "Jarovce" ||
            locality == "Rusovce" ||
            locality == "Čunovo")
        ) {
          city = "Bratislava";
        } else if (
          administrative_area_level_2.substring(0, 10) == "Bratislava" &&
          (sublocality == "Bratislava" ||
            sublocality == "Bratislava I" ||
            sublocality == "Bratislava II" ||
            sublocality == "Bratislava III" ||
            sublocality == "Bratislava IV" ||
            sublocality == "Bratislava V")
        ) {
          city = "Bratislava";
        } else {
          city = locality;
        }
      } else {
        city = locality;
      }

      // ID = null vzdy pre nove adresy
      newAddress.id = null;
      newAddress.latitude = latitude;
      newAddress.longitude = longitude;
      newAddress.displayName = displayName;
      newAddress.formattedAddress = formattedAddress;
      newAddress.country = country;
      newAddress.countryCode = country_code;
      newAddress.amenityName = amenityName;
      newAddress.amenityType = amenityType;
      newAddress.city = city;
      newAddress.cityDistrict = locality;
      newAddress.county = administrative_area_level_2;
      newAddress.houseNumber = street_number;
      newAddress.neighbourhood = neighbourhood ? neighbourhood : neighborhood;
      newAddress.postcode = postal_code;
      newAddress.road = route;
      newAddress.state = administrative_area_level_1;
      newAddress.suburb = sublocality;
      //Odovzdanie hodnoty do hlavného komponentu
      var temporaryAddress = [];
      temporaryAddress.latitude = latitude ? latitude : 0;
      temporaryAddress.longitude = longitude ? longitude : 0;
      temporaryAddress.displayName = displayName ? displayName : "";
      //this.$emit("input", Object.assign({}, temporaryAddress));
      this.updateValue(Object.assign({}, temporaryAddress));
      //Adresa/string, ktora je vybrata vo vyhladavacom poli
      //console.log("temporaryAddress", temporaryAddress);
      //this.value = Object.assign({}, temporaryAddress);
      this.items.push(Object.assign({}, temporaryAddress));
      //this.searchAddress = displayName;
      //console.log("displayName", displayName);
      //console.log("this.value", this.value);
      //Zaevidovanie adresy na server
      axios
        .put(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/place`,
          newAddress
        )
        .then((response) => {
          // console.log(response.data);
          var responseData = response.data;
          if (responseData.message == "ALREADY_EXISTS") {
            alert(
              this.$t("AutocompleteAddressRemote.msgAddErrorDuplicatedAlert")
            );
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "AutocompleteAddressRemote.msgAddErrorDuplicated"
            );
            this.snackbar.color = "error";
            //Evidovanie poctu duplicit do cookie a nastavenie Ban-u po 6 pokusoch
            var addingExistingAddressCounter = this.getCookie(
              "addingExistingAddressCounter"
            )
              ? parseInt(
                  JSON.parse(this.getCookie("addingExistingAddressCounter"))
                )
              : 0;
            if (addingExistingAddressCounter > 5) {
              this.duplicatedAddressBan = true;
              alert(
                this.$t("AutocompleteAddressRemote.msgAddErrorDuplicatedBan")
              );
              this.snackbar.show = "true";
              this.snackbar.message = this.$t(
                "AutocompleteAddressRemote.msgAddErrorDuplicatedBan"
              );
              this.snackbar.color = "error";
            }
            this.setCookie(
              "addingExistingAddressCounter",
              JSON.stringify(addingExistingAddressCounter + 1),
              0.04
            ); //zablokovať na 1 hodinu
          } else {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t(
              "AutocompleteAddressRemote.msgAddSuccess"
            );
            this.snackbar.color = "success";
          }
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgUpdateError");
          this.snackbar.color = "error";
        });
      this.findNewPlaceDialog = false;
    },
    /*initMap() {
      const map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 40.749933, lng: -73.98633 },
        zoom: 13,
        mapTypeControl: false,
      });
      const card = document.getElementById("pac-card");
      const input = document.getElementById("pac-input");
      const biasInputElement = document.getElementById("use-location-bias");
      const strictBoundsInputElement =
        document.getElementById("use-strict-bounds");
      const options = {
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
        types: ["establishment"],
      };
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(card);
      const autocomplete = new google.maps.places.Autocomplete(input, options);
      // Bind the map's bounds (viewport) property to the autocomplete object,
      // so that the autocomplete requests use the current map bounds for the
      // bounds option in the request.
      autocomplete.bindTo("bounds", map);
      const infowindow = new google.maps.InfoWindow();
      const infowindowContent = document.getElementById("infowindow-content");
      infowindow.setContent(infowindowContent);
      const marker = new google.maps.Marker({
        map,
        anchorPoint: new google.maps.Point(0, -29),
      });
      autocomplete.addListener("place_changed", () => {
        infowindow.close();
        marker.setVisible(false);
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }
        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }
        marker.setPosition(place.geometry.location);
        marker.setVisible(true);
        infowindowContent.children["place-name"].textContent = place.name;
        infowindowContent.children["place-address"].textContent =
          place.formatted_address;
        infowindow.open(map, marker);
      });
      // Sets a listener on a radio button to change the filter type on Places
      // Autocomplete.
      function setupClickListener(id, types) {
        const radioButton = document.getElementById(id);

        radioButton.addEventListener("click", () => {
          autocomplete.setTypes(types);
          input.value = "";
        });
      }

      setupClickListener("changetype-all", []);
      setupClickListener("changetype-address", ["address"]);
      setupClickListener("changetype-establishment", ["establishment"]);
      setupClickListener("changetype-geocode", ["geocode"]);
      setupClickListener("changetype-cities", ["(cities)"]);
      setupClickListener("changetype-regions", ["(regions)"]);
      biasInputElement.addEventListener("change", () => {
        if (biasInputElement.checked) {
          autocomplete.bindTo("bounds", map);
        } else {
          // User wants to turn off location bias, so three things need to happen:
          // 1. Unbind from map
          // 2. Reset the bounds to whole world
          // 3. Uncheck the strict bounds checkbox UI (which also disables strict bounds)
          autocomplete.unbind("bounds");
          autocomplete.setBounds({
            east: 180,
            west: -180,
            north: 90,
            south: -90,
          });
          strictBoundsInputElement.checked = biasInputElement.checked;
        }

        input.value = "";
      });
      strictBoundsInputElement.addEventListener("change", () => {
        autocomplete.setOptions({
          strictBounds: strictBoundsInputElement.checked,
        });
        if (strictBoundsInputElement.checked) {
          biasInputElement.checked = strictBoundsInputElement.checked;
          autocomplete.bindTo("bounds", map);
        }

        input.value = "";
      });
    },*/
    initSearch() {
      //const input = document.getElementById("pac-input");
      const input = /** @type {!HTMLInputElement} */ (this.$refs.autocomplete);

      const options = {
        fields: [
          "formatted_address",
          "address_components",
          "geometry",
          "name",
          "icon",
        ],
        strictBounds: false,
        //types: ["establishment"],
        types: [],
      };
      var bounds;
      var boundsSw;
      var boundsNe;
      if (!bounds || bounds == null) {
        boundsSw = new google.maps.LatLng(47.1, 17.1);
        boundsNe = new google.maps.LatLng(49.1, 19.1);
        //console.log("1", boundsNe);
        bounds = new google.maps.LatLngBounds(boundsSw, boundsNe);
        //console.log("bounds 2:", bounds);
        this.autocomplete = new google.maps.places.Autocomplete(input, options);
      } else {
        bounds = this.mapBounds;
      }
      //console.log("bounds 3:", bounds);
      this.autocomplete.setBounds(bounds);
      //autocomplete.bindTo(bounds);
      this.autocomplete.addListener("place_changed", () => {
        const place = this.autocomplete.getPlace();
        this.newPlace = place;
        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert(
            this.$t("AutocompleteAddressRemote.msgNoAddress") +
              ": '" +
              place.name +
              "'"
          );
          return;
        }
        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          //console.log("place.geometry.viewport", place.geometry.viewport);
        } else {
          //console.log("place.geometry.location", place.geometry.location);
        }
        //console.log("place.geometry.location", place.geometry.location);
        //console.log("place.name", place.name);
        //console.log("place.formatted_address", place.formatted_address);
      });
    },
    //Vytvorenie a aktualizácia cookie
    setCookie(nameC, valueC, daysToLive) {
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = nameC + "=" + encodeURIComponent(valueC);
      if (typeof daysToLive === "number") {
        var secondsExpired = Math.round(daysToLive * 24 * 60 * 60);
        /* Sets the max-age attribute so that the cookie expires after the specified number of days */
        cookie += "; max-age=" + secondsExpired + "; path=/;SameSite=strict"; //; domain=taxximo.com
        document.cookie = cookie;
      }
    },
    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },
  },

  beforeDestroy() {
    clearInterval(this.debounce);
  },
};
</script>
